.custom-store {
  font-weight: bolder;
}

.w-100 {
  width: 80% !important;
}

.w-35 {
  width: 35% !important;
}

.w-15 {
  width: 15% !important;
}

.w-20 {
  width: 20% !important;
}
